.layout {

    position: relative;
    display: flex;
    height: 100vh;
    flex-direction: column;

    &__header {
        position: relative;
        display: flex;
        justify-content: space-between;
        width: 100%;
        background: #000;
        border: $white-border-1;
        border-radius: .5rem;
    }
    
    &__main {
        position: relative;
        //background-image: linear-gradient(to bottom right, rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.95)), url(../../assets/images/gavel.jpg);
        height: 100%;
        width: 100%;
        background-size: cover;
        background-attachment: fixed;
        background-position: top;
        display: flex;
        justify-content: center;
        align-items: center;
        border-right: $white-border-1;
        border-left: $white-border-1;
    }
    
    &__footer {
        position: relative;
        width: 100%;
        color: #fff;
        background: #000;
        padding: .3rem;
        font-weight: 600;
        font-size: 1.2rem;
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-family: $text;
        border: $white-border-1;
        border-radius: .5rem;
    }    

    &__footer-badge {
        position: relative;

        a img {
                display: block;
                margin-top: 3px;
            }
    }
}

/*
<a style="background-color:black;color:white;text-decoration:none;padding:4px 6px;font-family:-apple-system, BlinkMacSystemFont, &quot;San Francisco&quot;, &quot;Helvetica Neue&quot;, Helvetica, Ubuntu, Roboto, Noto, &quot;Segoe UI&quot;, Arial, sans-serif;font-size:12px;font-weight:bold;line-height:1.2;display:inline-block;border-radius:3px" href="https://unsplash.com/@bill_oxford?utm_medium=referral&amp;utm_campaign=photographer-credit&amp;utm_content=creditBadge" target="_blank" rel="noopener noreferrer" title="Download free do whatever you want high-resolution photos from Bill Oxford"><span style="display:inline-block;padding:2px 3px"><svg xmlns="http://www.w3.org/2000/svg" style="height:12px;width:auto;position:relative;vertical-align:middle;top:-2px;fill:white" viewBox="0 0 32 32"><title>unsplash-logo</title><path d="M10 9V0h12v9H10zm12 5h10v18H0V14h10v9h12v-9z"></path></svg></span><span style="display:inline-block;padding:2px 3px">Bill Oxford</span></a>
*/