.about {

    width: 100%;
    height: 100%;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    
    /*position: relative;
    height: 95rem;
    color: $color-gray;
    margin: 0 auto; */

    /*
    @include respond(phone-secondary) {
        height: 105rem;
    }

    @media only screen and(max-width: $custom-media-query-1) {
       height: 110rem;
    }

    @media only screen and(max-width: $custom-media-query-2) {
       height: 120rem;
    }

    @include respond(phone) {
        height: 118rem;
    }

    @media only screen and(max-width: $custom-media-query-3) {
        height: 130rem;
    }

    @media only screen and(max-width: $custom-media-query-4) {
        height: 145rem;
    }

    @media only screen and(max-width: $custom-media-query-5) {
        height: 127rem;
    }

    */

    &__box {
        position: relative;
        max-width: 80rem;

        @media only screen and(max-width: $custom-media-query-2) {
           padding: 4rem;
        }
    }

    &__picture {
        position: relative;
        float: left;
        width: 26rem;
        height: 33rem;
        margin-right: 5rem;
        margin-bottom: 3rem;
        text-align: center;

        animation: moveInLeft 1.5s ease-out;

        @include respond(phone) {
            width: 20rem;
            height: 27rem;
        }

        &-photo {
            width: 100%;
            border-radius: 50%;
            border: solid #fff .5rem;
            margin-bottom: 3rem;
            box-shadow: 0 .3rem .3rem rgba($color-dark, .3);     
            transition: all 3s ease-in 3.5s;
        }

        &:hover &-photo {
            transform-origin: top left;
            transform: scale(0) rotate(360deg);
        }
    }

    &__description {
        margin: 0 auto;

        .text {
            animation: fadeInRight 3s ease-out;
        }
    }

    &__description-quote-box {
        display: flex;
        justify-content: flex-end;

        animation: fadeInBottom 4s ease-out;
        
        .quote {
            width: 45%;
        } 
    }
}