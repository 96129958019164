.contact {
    color: $color-gray;

    /*
    @include respond(phone) {
        height: 40vh;
    }

    @media only screen and(max-width: $custom-media-query-5) {
        height: 30vh;
     }
     */

    &__box {
        display: flex;
        justify-content: center;
    }

    &__list {
        display: flex;
        flex-direction: column;
    }

    &__nav-icon {
        height: 4.5rem;
        width: 4.5rem;
        fill: #fff;
        transition: all .4s;
    }

    &__list-item {
        display: inherit;
        align-items: center;
        text-align: center;
        color: #fff;

        &:first-child {
            animation: moveInLeft 1.5s ease-out;
        }

        &:nth-child(2) {
            animation: fadeInRight 3s ease-out;
        }

        &:last-child {
            animation: fadeInBottom 4s ease-out;
        }
        
        i {
            font-size: 4.5rem;
            transition: all .4s;
        }

        a {
            font-size: 2.2rem;
            margin-left: 4rem;
            flex: 1;
        }

        &:last-child a {
            margin-left: 4.7rem;
        }

        &:not(:last-child) {
            margin-bottom: 4rem;
        }

        &:hover .contact__nav-icon {
            transform: scale(1.4);
        }

        &:hover .mail {
            fill: #9498c8;
        }

        &:hover .twitter {
            fill: #55acee;
        }

        &:hover .facebook {
            fill: #3b5999;
        }

        &:hover a {
            @include buttonNameBlackToWhite;
        }
    }
}