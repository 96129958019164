.work {
  width: 100%;
  position: relative;
  max-width: 80rem;

  &__box-list {
    display: flex;
    justify-content: space-around;

    @media only screen and(max-width: $custom-media-query-1) {
      flex-direction: column;
      align-items: center;
    }
  }

  &__nav-icon {
    fill: $color-gray;
    height: 15rem;
    width: 15rem;
  }

  &__box {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;

    &-1 {
      top: -3rem;
      animation: moveInLeft 1.5s ease-out;
    }

    &-2 {
      top: 3rem;
      animation: fadeInRight 3s ease-out;
    }

    &-3 {
      top: -3rem;
    }

    &-title {
      margin: 0 auto;

      .title-secondary {
        border-bottom: solid 0.2rem transparent;
      }
    }
  }

  &__box-list &__box:hover .title-secondary {
    border-bottom: solid 0.2rem $color-white;
  }

  &__box-list &__box:hover .button-label {
    @include buttonIconWhiteToBlack;

    .work__nav-icon {
      fill: #fff;
    }
  }
  /*
    .input__invisible:focus + .button-label {
        @include buttonIconWhiteToBlack;
    } */
}
