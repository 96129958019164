.error {
  height: 100%;
  width: 100%;
  background-color: #000;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  &__icon {
    transition: all 0.7s;
    fill: #fff;
    height: 34rem;
    width: 36rem;
  }

  &__icon:hover {
    transform: rotate(180deg);
  }

  &__icon:hover ~ &__title {
    border-bottom: solid #fff 0.4rem;
    margin-top: 3rem;
  }

  &__title {
    transition: all 0.7s;
    margin-top: 2rem;
    font-weight: 300;
    font-size: 3.7rem;
    border-bottom: solid transparent 0.4rem;
  }
}
