body {
    font-family: $title;
    font-weight: 400;
    line-height: 1.7;
    color: $color-white; 
}

.heading-primary {
    color: $color-white;
    //text-transform: uppercase;

    /* hack to correct minor "shake" bug(?) in animations */
    backface-visibility: hidden;
    margin-bottom: 4rem;

    
    &--main {
        display: block;
        font-size: 5.4rem;
        font-weight: 400;
        letter-spacing: .3rem;
    
        animation: moveInLeft 1.5s ease-out;
    } 

    &--sub {
        backface-visibility: hidden;
        display: block;
        font-size: 2.5rem;
        letter-spacing: .9rem;

        animation: fadeInRight 3s ease-out;
    }
}

.title {
    font-size: 4rem;
    text-align: center;
    text-shadow: 0 .3rem .2rem rgba($color-dark, .2);
}

.title-secondary {
    font-size: 2.5rem;
    text-align: center;
}

.title-tertiary {
    font-size: 2.5rem;
    text-align: center; 
    text-shadow: 0 .3rem .2rem rgba($color-dark, .2);
}

.text {
    font-family: $text;
    hyphens: auto;
    text-align: justify;
    font-size: 2rem;
}

.quote {
    font-size: 1.3rem; 
    font-style: italic;
    color: $color-gray-light-2;
}