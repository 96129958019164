*,
*::after,
*::before {
    margin: 0;
    padding: 0;
    box-sizing: inherit;
}

html {

    font-size: 62.5%;

    @include respond(tab-land) {
        font-size: 56.25%; // 1rem = 9px, 9/16 = 56.25%
    }

    @include respond(tab-port) {
        font-size: 50%;  // 1rem = 8px, 8/16 = 50%
    }

    @include respond(phone) { // Things get weird at this point - drastic change of design required
        font-size: 43.75%;  // 1rem = 7px, 7/16 = 43.75%
    }

    @media only screen and (max-width:$custom-media-query-5) {
        font-size: 31.25%; // 1rem = 7px, 5/16 = 31.25%
    }
    
    @include respond(big-desktop) {
        font-size: 75%;    // 1rem = 12, 12/16 = 75
    }
}

body { 
    overflow-x: hidden;
    box-sizing: border-box;
}

::selection {
    background: $color-dark;
    color: $color-white;
}