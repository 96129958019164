// popup screen, which covers the below current screen when activated
.popup {
    height: 100vh;
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 30;
    background: rgba($color-dark, .5);
    color: $color-gray;

    display: flex;
    justify-content: center;
    align-items: center;

    opacity: 0;
    visibility: hidden;
    transition: all .2s;

    /*
    @supports (-webkit-backdrop-filter: blur(10px)) or (backdrop-filter: blur(10px)) {
        -webkit-backdrop-filter: blur(10px);
        backdrop-filter: blur(10px);  
    }*/

    // This is the 'box' where the content of the popup is shown
    &__content {
        position: absolute;
        background: $color-white;
        height: 80%;
        max-width: 90rem;
        border-radius: .7rem;
        border: solid .4rem $color-dark;
        background: $color-gray-light;
        box-shadow: 0 1rem 1rem rgba($color-dark, .3);
        padding: 3rem 6rem;
        transform: scale(.1);
        opacity: 0;
        transition: transform .6s, opacity .2s;
        overflow: auto;

        @include respond(phone){
            max-width: 100%;
            height: 100%;
        }

        @media only screen and(max-width: $custom-media-query-4) {
            padding: 1rem 3rem;
         }
    }



    @include respond(tab-port) {
        overflow: auto;
    }




    // the small variation of the popup;
    &__content--small {
        height: auto;
    }

    &__list {
        list-style: none;
        margin-bottom: 4rem;

        @media only screen and(max-width: $custom-media-query-4) {
            margin-bottom: 6rem;
         }
    }

    &__list-item {
        &:not(:first-child) {
            margin-top: 3rem;
        }
    }

    &__box {
        display: flex;
        align-items: flex-start;
        margin-top: 2rem;
        padding-bottom: 3rem;
        border-bottom: solid .1rem rgba($color-gray, .3);

        @include respond(phone) {
            flex-direction: column;
            margin-top: 3rem;
        }
    }

    &__box--no-border-bottom {
        border-bottom: none;
    }

    &__box-picture {
        width: 85rem;
        overflow: hidden;
        flex: 0 0 30%;
        margin-right: 3rem;

        @include respond(phone) {
            width: auto;
        }

        @media only screen and(max-width: $custom-media-query-4) {
            flex-direction: column;
            align-items: center;
         }
    }

    &__picture {
        width: 100%;
        // height: 18rem;
        display: block;
        border-radius: .7rem;
        border: solid black .2rem;

        @include respond(phone) {
            width: auto;
            margin-bottom: 2rem;
        }
    }

    &__box-description {
        display: inherit;
        margin-left: 3rem;
        flex-direction: column;
        justify-content: space-between;

        @include respond(phone) {
            margin-left: 0;
        }
    }

    &__box-link {
        display: inherit;
        justify-content: space-between;
        margin-top: 3rem;
    }

    &:target {
        opacity: 1;
        visibility: visible;
    }

    &:target &__content {
        opacity: 1;
        transform: scale(1);
    }
}