.header {
    width: 100%;
    height: 100%;
    background-image: linear-gradient(to bottom, rgba($color-dark, .3), rgba($color-dark, .95)), url(../../assets/images/Castle-bg-small.jpg);
    background-size: cover;
    background-position: top;
    //-webkit-clip-path: polygon(0px 0px, 100% 0px, 100% 90vh, 50% 100%, 0px 90vh);  
    //clip-path: polygon(0px 0px, 100% 0px, 100% 90vh, 50% 100%, 0px 90vh); 
    position: relative;
    background-attachment: fixed;
    display: flex;
    justify-content: center;
    align-items: center;
    
    // dpi = dots per inch, if resolutions is higher than 192dpi AND width higher than 600px OR 2000px, use this background-image instead.
    @media only screen and (min-resolution: 192dpi) and (min-width: 37.5em),
            only screen and (-webkit-min-device-pixel-ratio: 2) and (min-width: 37.5em), // for safari
            only screen and (min-width: 125em) {
        background-image: linear-gradient(to bottom, rgba($color-dark, .2),
                          rgba($color-dark, .95)), 
                          url(../../assets/images/Castle-bg.jpg);
    }

    &__text-box {
        position: relative;
        text-align: center;
    }

    &__logo-box {
        position: relative;
    }

    &__logo-box-secondary {
        position: absolute;
        opacity: 0;
        top: 4rem;
        right: 4rem;
        transition: all 2s ease-in 3s;
    }

    &__logo {
        opacity: 1;
        width: 7rem;
        border: #fff solid .3rem;
        border-radius: 1rem;
        padding: .2rem .5rem;
        transition: all 2s ease-in 3s;

        animation: fadeIn 4.5s ease-in;
    }

    /*
    &__logo--secondary {
        opacity: 1;
        height: 6rem;
        transition: all 2s ease-in 3s;
        animation: none;
    }

    &__logo-box-secondary {
        opacity: 0;
    } */

    // logo dissapears 
    &__logo-box:hover &__logo {
        opacity: 0;
     } 

     /*
    &__logo-box:hover + &__logo-box-secondary {
        opacity: 1;
     } */
}
