.footer {
    background: $color-dark;
    padding: .5rem;

    display: flex;
    align-items: center;

    // for the w3c validator logo 
    p {
        font-size: 1.4rem;
        text-align: center;

        img {
            border: 0;
            padding-top: .5rem;
            height: 3rem;
        }

        &:first-child {
            margin-left: auto;
            flex: .95
        }

    }
}