// FONT-Families
$title: 'Pacifico', cursive;
$text: 'Source Sans Pro', sans-serif;

// COLORS
$color-white: #fff;

$color-dark: #000;

$color-gray: #6e6e6e;
$color-gray-2: #8e8e8e;
$color-gray-light: #f6f6f6;
$color-gray-light-2: #cacaca;

// BORDERS
$white-border-1: solid #fff .3rem;

// MEDIA SIZES
$custom-media-query-1: 46.875em;  // 750px/16;
$custom-media-query-2: 43.75em;  // 700px/16;
$custom-media-query-3: 31.25em;  // 500px/16;
$custom-media-query-4: 28.125em;  // 450px/16;
$custom-media-query-5: 23.43em;  // 375px/16;